<template>
    <section>
        <div class="row mx-0 custom-scroll">
            <div
            class="col-xl-6 col-lg-6 col-md col-sm px-0  overflow-auto custom-scroll border-right"
            :class="$route.name == 'admin.tiendas.produccion.productos' ? 'alto' : 'alto-leechero'"
            >
                <div v-if="categoria === 0" class="row mx-0 align-items-center border-bottom" style="height:40px;">
                    <p class="col-auto f-15 text-general">
                        Productos modificados
                    </p>
                    <el-popover placement="bottom" effet="light" popper-class="p-2 br-8" class="ml-auto">
                        <div class="p-2 cr-pointer f-12 item" @click="modalRestablecerValores">
                            Restablecer valores
                        </div>
                        <div slot="reference" class="cr-pointer">
                            <i class="icon-dots-vertical f-18 text-general" />
                        </div>
                    </el-popover>
                </div>
                <div class="p-2 f-15 overflow-auto custom-scroll chat-mensajes" style="height: calc(100% - 45px);">
                    <div v-infinite-scroll="getFunction" infinite-scroll-disabled="busy" infinite-scroll-immediate-check="false" infinite-scroll-distance="10">
                        <div v-if="tab === 'stock'" class="row mx-0 justify-center">
                            <card-stock
                            v-for="(prod, idx) in productos" :key="`prod-${idx}`"
                            :producto="prod"
                            class="mb-3"
                            />
                        </div>
                        <div v-if="tab === 'rotacion'" class="row mx-0 justify-center">
                            <card-rotacion
                            v-for="(prod, idx) in productos" :key="`prod-${idx}`"
                            :producto="prod"
                            class="mb-3"
                            />
                        </div>

                        <contentLoader v-show="loading" />
                    </div>
                    <div v-show="!productos.length" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 f-600 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="80" src="/img/no-imagen/click.svg" alt="" />
                            </div>
                            <p>Selecciona una de las categorías del panel de la izquierda.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 px-0 alto">
                <detalle />
            </div>
        </div>
        <modal-confirmar
        ref="modalRestablecerValores"
        titulo="Restablecer Valores"
        mensaje="¿Esta seguro de restablecer los valores de todos los productos modificados?"
        no-aceptar
        adicional="Restablecer"
        @adicional="restablcerPreciosPropios"
        />
    </section>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import Productos from "~/services/productos/productosTendero"
import {mapGetters} from 'vuex'
export default {
    directives: {infiniteScroll},
    components: {
        detalle: () => import('./detalle'),
        cardRotacion: () => import('./components/cardRotacion.vue'),
        cardStock: () => import('./components/cardStock.vue')
    },
    props:{
        categoria: {
            type: Number,
            default: 0
        },
        tab: {
            type: String,
            default: 'stock'
        },
    },
    data(){
        return {
            id_tienda_ruta: parseInt(this.$route.params.id_tienda),
            busy: false,
            porcentaje: '',
            verDetalle: 0,
            producto_activo:0,
            loading:false,
            page:0,
            productos:[]
        }
    },
    computed:{
        ...mapGetters({
            query: 'productos/productos/query',
        }),
    },
    watch:{
        categoria(value){
            this.setCategoria()
        },
        tab(value){
            this.page = 0
            this.productos = []
            this.busy = false
        }
    },
    methods: {
        setCategoria(){

            this.$store.commit('productos/productos/set_id_producto', null)
            this.page = 0
            this.productos = []
            this.busy = false

            if(this.tab == 'stock'){
                this.listarProductosTabStock()
            }
            if(this.tab == 'rotacion'){
                this.listarProductosTabRotacion()
            }
        },
        async listarProductosTabStock(){
            try {
                if(this.categoria == null){
                    console.log('Por favor seleccione una categoria');
                    return
                }
                if(this.categoria == -1)return
                this.page = this.page + 1
                this.loading = true
                this.busy = true;
                let params = {
                    categoria: this.categoria,
                    tienda: this.id_tienda_ruta ? this.id_tienda_ruta : this.$usuario.tienda.id,
                    page:this.page,
                    query:this.query
                }
                const {data} = await Productos.listarProductosTabStock(params)

                this.productos.push(...data.productos.data);

                if(data.productos.current_page === data.productos.last_page){
                    this.busy = true;
                }else{
                    this.busy = false;
                }

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        async listarProductosTabRotacion(){
            try {
                if(this.categoria == null){
                    console.log('Por favor seleccione una categoria');
                    return
                }
                if(this.categoria == -1)return
                this.page = this.page + 1
                this.loading = true
                this.busy = true;
                let params = {
                    categoria: this.categoria,
                    tienda: this.id_tienda_ruta ? this.id_tienda_ruta : this.$usuario.tienda.id,
                    page:this.page,
                    query:this.query
                }
                const {data} = await Productos.listarProductosTabRotacion(params)

                this.productos.push(...data.productos.data);

                if(data.productos.current_page === data.productos.last_page){
                    this.busy = true;
                }else{
                    this.busy = false;
                }

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        getFunction(){
            if(this.tab == 'stock'){
                this.listarProductosTabStock()
            }
            if(this.tab == 'rotacion'){
                this.listarProductosTabRotacion()
            }
        },
        modalRestablecerValores(){
            this.$refs.modalRestablecerValores.toggle()
        },
        async restablcerPreciosPropios(){
            try {
                const idTienda = this.id_tienda_ruta ? this.id_tienda_ruta : this.$usuario.tienda.id

                const {data} = await Productos.restablecerValoresProductosTienda(idTienda)
                this.notificacion('Éxito','Valores restaurados correctamente','success')
                this.$refs.modalRestablecerValores.toggle()

            } catch (error){
                this.error_catch(error)
            }
        }

    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.item:hover{
    background-color: #F6F9FB;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 195px);
}
.alto-leechero{
    height: calc(100vh - 67px);
}
.bg-gr-prod{
    background: linear-gradient( to right, #03D6BC20, #0D4DFF20)
}
/*-*-*-*-*-* Media Queries -*-*-*-*-*-*/
@media only screen and (min-width : 768px) and (max-width : 1000px) {
    .alto-leechero{
        height: calc(100vh - 167px);
    }
}
@media (max-width: 768px){
    .alto-leechero{
        height: calc(100vh - 167px);
    }
}
</style>
